import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import { History } from 'history';
import Globals from '../../../utils/Globals';
import ReportCategoryCollection from '../models/ReportCategoryCollection';
import ReportSavedModel from '../models/ReportSavedModel';
import ReportModel from '../models/ReportModel';
import ReportCategoryModel from '../models/ReportCategoryModel';
import ReportCategoryView from './ReportCategoryView';
import EditReportCategoryView from './EditReportCategoryView';
import AlertView from '../../app/views/AlertView';
import ConfirmView from '../../app/views/ConfirmView';
import WaitView from '../../app/views/WaitView';
import Actionbar from '../../app/components/documentexplorer/actionbar/Actionbar';
import html from '../templates/report_list_view.template';

interface BackboneView extends Backbone.View {
    options: any;
}

interface ViewOptions extends Backbone.ViewOptions<ReportCategoryModel> {
    enableHelp: Function;
    history: History;
    editReportCategoryView?: BackboneView;
}

class ReportListView extends Backbone.View {
    options: ViewOptions;
    collectionLength: number = 0;
    reportCategoryViews: any;
    constructor(options: ViewOptions) {
        super(options);
        this.options = options;
        $('body').scrollTo(0, 0);
        this.reportCategoryViews = [];
    }

    render() {
        const reportCategory = new ReportCategoryCollection();
        const view = this;
        this.reportCategoryViews = [];
        this.$el.spinner();
        const compiled = _.template(html);
        view.$el.html(compiled({ $, Actions: Globals.actions, schemaUserModel: Globals.schemaUserModel }));
        view.renderTemplateSuccess();
        view.$('#reportzeilen').spinner();
        reportCategory.fetch({
            success(collection: any) {
                view.collectionLength = collection.length;
                view.$('#reportzeilen').html('');
                if (collection.length > 0) {
                    collection.eachAsync(
                        (model: any, callback: any) => {
                            console.log('get');
                            view.addReportCategoryView(model, () => {
                                console.log('done!');
                                callback();
                            });
                        },
                        (err: any) => {
                            console.log('Category Done', err);
                        },
                        10
                    );
                    view.collection = collection;
                    // view.checkInWork();
                    view.pollStatus();
                    $('#reportzeilen').prepend('<div id="actionbarHolder"></div>');
                    const actionbar = new Actionbar();
                    const actions = [
                        {
                            className: 'delete',
                            label: $._('report.list.delete'),
                            action: 'click',
                            target: '.report.selected .reportactions .loeschen',
                            confirm: $._('report.list.delete.all.message'),
                        },
                        // {className: 'open', label: 'Öffnen', action: "click", target: '.report.selected .reportactions .link-html', confirm: false},
                        {
                            className: 'close',
                            label: $._('report.list.close'),
                            action: 'click',
                            target: '.reportactions .listSelector.true',
                            confirm: false,
                        },
                    ];
                    actionbar.init('actionbarHolder', actions);
                }
            },
        });
        reportCategory.sortByField('name', 'asc');
        view.$('#fieldsetStatussearch,#fieldsetUsersearch').uiselectfield('init');
        return view;
    }
    unselectAll() {
        $('.reportactions .listSelector.true').trigger('click');
    }
    addReportCategoryView(model: any, callback: any) {
        const view = this;
        const el = $('<div />', {
            id: 'reportcategory' + model.id,
            'data-id': model.id,
            class: 'reportcategory reporttyp',
        });
        if (!Globals.actions.can('report.list.new.template') && !Globals.actions.can('report.list.delete.category')) {
            $(el).hide();
        }
        view.$('#reportzeilen').append(el);
        const reportCategoryView = new ReportCategoryView({
            el,
            model,
            afterRender: callback,
            history: view.options.history,
            reportListView: view,
            enableHelp: view.options.enableHelp,
        });
        model.view = reportCategoryView;
        view.reportCategoryViews.push(reportCategoryView);
        reportCategoryView.render();
    }
    onlyone() {
        const view = this;
        if (view.collectionLength === 1) {
            view.$('.typbeschriftung.hasReports .opentype:not(.active)').trigger('click');
        }
    }
    renderTemplateSuccess() {
        const view = this;
        // this.$( "#datesearchfrom" ).fadeOut('slow');
        this.$('#datesearchfrom').datepicker({
            changeMonth: true,
            changeYear: true,
            onSelect(dateText: string) {
                view.$('#datesearchto').datepicker('option', 'minDate', dateText);
                view.$('#report-searchform').validationEngine('updateRule', '#datesearchto', 'past', dateText);
                view.doSearch();
                return true;
            },
        });
        this.$('#datesearchto').datepicker({
            changeMonth: true,
            changeYear: true,
        });
    }
    events() {
        return {
            'keyup input[name="search"]': 'doSearch',
            'change #statussearch': 'doSearch',
            'change #usersearch': 'doSearch',
            'change fieldset.datesearch-from-to input': 'doSearch',
            'click span#deleteFilterItems': 'deleteFilterItems',
            'click button.addReportCategory': 'addReportCategory',
            'click span#clearFilter': 'clearFilters',
            'click span#closeAll': 'closeAll',
            'click #datesearchfrom': 'triggerDatepicker',
            'focus #datesearchfrom': 'triggerDatepicker',
            'click #datesearchto': 'triggerDatepicker',
            'focus #datesearchto': 'triggerDatepicker',
        };
    }
    triggerDatepicker(evt: any) {
        if (evt.type === 'focus') {
            $(evt.target).data('focus', true);
        }
        let pageXfrom = 879;
        const width = 18;
        const pageYfrom = 136;
        const height = 15;
        if (evt.target.id === 'datesearchto') {
            pageXfrom = 1056;
        }
        if (
            $(evt.target).data('focus') === false &&
            $(evt.target).datepicker('widget').is(':visible') &&
            evt.pageX >= pageXfrom &&
            evt.pageX <= pageXfrom + width &&
            evt.pageY >= pageYfrom &&
            evt.pageY <= pageYfrom + height
        ) {
            this.$(evt.target as any).datepicker('hide');
        } else {
            this.$(evt.target as any).datepicker('show');
        }
        if (evt.type === 'click') {
            $(evt.target).data('focus', false);
        }
    }
    clearFilters() {
        const view = this;
        view.$('#datesearchfrom').datepicker('setDate', '');
        view.$('#datesearchto').datepicker('setDate', '');
        view.$('#statussearch').uiselectfield('setValue', '');
        view.$('#usersearch').uiselectfield('setValue', '');
        view.$('#datesearchto').datepicker('option', 'minDate', null);
        view.$('#report-searchform').validationEngine('updateRule', '#datesearchto', 'past', null);
        view.$('#namesearch').val('');
        view.doSearch(true);
        view.closeAll();
        view._filteredIds = [];
        $.scrollTo(0, 200);
    }
    closeAll() {
        _.each(this.reportCategoryViews, (reportCategoryView) => {
            _.each(reportCategoryView.reportViews, (reportView) => {
                reportView.triggerReportsSaved(false);
            });
            reportCategoryView.triggerReports(false);
        });
        /* $(".typbeschriftung.hasReports.active").trigger('click');
        $(".reporttitel.hasReportsSaved.active").trigger('click'); */
        $.scrollTo(0, 200);
    }
    addReportCategory() {
        const view = this;
        if (_.isUndefined(view.options.editReportCategoryView)) {
            view.options.editReportCategoryView = new EditReportCategoryView({
                reportListView: view,
            });
        }
        if (view.options.editReportCategoryView !== undefined) {
            view.options.editReportCategoryView.model = new ReportCategoryModel();
            view.options.editReportCategoryView.render();
        }
    }
    _checkStatus(view: Backbone.View) {
        const search = this.$('#statussearch').val();
        if (search === '' || (view.model as any).hasStatus(search)) {
            return true;
        }
        return false;
    }
    _checkUserid(view: Backbone.View) {
        const search = this.$('#usersearch').val();
        if (search === '' || view.model.get('user_id') + '' === search) {
            return true;
        }
        return false;
    }
    _checkName(view: Backbone.View) {
        const search = this.$('input[name="search"]').val();
        // if (search === '') {
        //     return false;
        // }
        const regExp = new RegExp('^' + search, 'i');
        const name = view.$('span.description').html();
        if (search === undefined || search === '' || (name !== undefined && name.search(regExp) !== -1)) {
            return true;
        }
        return false;
    }
    _checkDate(view: Backbone.View) {
        let dateFrom: any = this.$('#datesearchfrom').val() as string;
        let dateTo: any = this.$('#datesearchto').val();
        const value = Date.parse(view.$('td.reportname').html());
        let ret = true;
        if (dateFrom !== '') {
            dateFrom = Date.parse(dateFrom);
            if (dateFrom.compareTo(value) === 1) {
                ret = false;
            } else {
                ret = true;
            }
        }
        if (ret && dateTo !== '') {
            dateTo = Date.parse(dateTo);
            dateTo = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59);
            if (dateTo.compareTo(value) === -1) {
                ret = false;
            } else {
                ret = true;
            }
        }
        return ret;
    }
    _checkFields(model: ReportListView) {
        const that = this;
        if (that._checkStatus(model) && that._checkName(model) && that._checkDate(model) && that._checkUserid(model)) {
            return true;
        }
        return false;
    }
    _filteredIds: any;
    doSearch(isClear?: boolean) {
        const that = this;
        if (that.$('#report-searchform').validationEngine('validate')) {
            that._filteredIds = [];
            _.each(this.reportCategoryViews, (reportCategoryView) => {
                let hasResultsTyp = false;
                _.each(reportCategoryView.reportViews, (reportView) => {
                    let hasResults = false;
                    _.each(reportView.reportSavedViews, (reportSavedView) => {
                        reportSavedView.hideForSearch(false);
                        if (that._checkFields(reportSavedView)) {
                            that._filteredIds.push(reportSavedView.model.id);
                            hasResults = true;
                        } else {
                            reportSavedView.hideForSearch(true);
                        }
                    });
                    if (hasResults) {
                        hasResultsTyp = true;
                        reportView.reportHasReportsSaved(true);
                        if (isClear !== true) {
                            reportView.triggerReportsSaved(true); // Ticket https://mabinso.atlassian.net/browse/MABINSO-2360 Das hier verhindert aufklappen.
                        }
                        reportView.hideReportDescription(false);
                    } else {
                        reportView.hideReportDescription(true);
                        reportView.triggerReportsSaved(false);
                    }
                });
                if (hasResultsTyp) {
                    reportCategoryView.categoryHasReports(true);
                    if (isClear !== true) {
                        reportCategoryView.triggerReports(true); // Ticket https://mabinso.atlassian.net/browse/MABINSO-2360 Das hier verhindert aufklappen.
                    }
                } else {
                    reportCategoryView.triggerReports(false);
                }
            });
            this.countReports();
            $.scrollTo(0, 200);
        }
        if (that._filteredIds.length === 0) {
            that.closeAll();
        }
    }
    countReports() {
        _.each(this.reportCategoryViews, (reportCategoryView) => {
            _.each(reportCategoryView.reportViews, (reportView) => {
                if (reportView.length > 0) {
                    reportView.countReportSavedItems();
                }
            });
        });
    }
    reportSavedPoll: any;
    pollStatus() {
        const view = this;
        this.reportSavedPoll = new ReportSavedModel();
        this.reportSavedPoll.pollStatus((reportSaved: any) => {
            console.log('Poll Change Event Received');
            console.log(Globals.schemaUserModel.id + ' vs ' + reportSaved.get('schema_id'));
            if (Globals.schemaUserModel.id === reportSaved.get('schema_id')) {
                const category = view.collection.get(reportSaved.get('reportcategory_id')) as any;
                if (category != null) {
                    const report = category.view.collection.get(reportSaved.get('report_id'));
                    if (report != null) {
                        let oldRs;
                        if (!_.isUndefined(report.view.collection)) {
                            oldRs = report.view.collection.get(reportSaved.id);
                        }
                        if (oldRs != null) {
                            console.log('update');
                            oldRs.view.setStatus(reportSaved);
                        } else {
                            console.log('add');
                            // Update Modal Wait View
                            const modalWait = $('div.modalWait');
                            if (modalWait) {
                                modalWait.find('.modalYes').show();
                                modalWait.find('.modalYes').data('id', reportSaved.id);
                            }
                            report.view.addReportSavedView(reportSaved, 'after');
                        }
                    }
                }
            }
        });
    }
    stopPollStatus() {
        if (this.reportSavedPoll != null) {
            this.reportSavedPoll.closePollStatus();
            this.reportSavedPoll = null;
        }
    }
    remove() {
        this.stopPollStatus();
        this.$el.remove();
        return this;
    }
    deleteFilterItems() {
        const that = this;
        if (that._filteredIds == null || _.size(that._filteredIds) === 0) {
            AlertView.show({
                title: $._('report.deleteFilterItems.noitems.title'),
                message: $._('report.deleteFilterItems.noitems.message'),
            });
        } else {
            ConfirmView.show({
                title: $._('report.deleteFilterItems.delete.title'),
                message: $._('report.deleteFilterItems.delete.message', _.size(that._filteredIds).toString()),
                yesFunc() {
                    const waitView = new WaitView({
                        message: $._('report.deleteFilterItems.delete.wait'),
                    });
                    ReportModel.deleteItems({
                        items: that._filteredIds,
                        success() {
                            setTimeout(() => {
                                waitView.remove();
                                AlertView.show({
                                    message: $._('report.deleteFilterItems.delete.success'),
                                    okFunc() {
                                        that.render();
                                    },
                                });
                            }, 2000);
                        },
                    });
                },
            });
        }
    }
}

export default ReportListView;

import $ from 'jquery';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { _, Spinner, Help } from '@mabinso-registry/module-commons';
import Header from '@mabinso-registry/header';
import React, { lazy } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Globals from '../../utils/Globals';
import AppRoutes from '../index';
import ModuleRoutes from '../../modules';
import Moduleholder from '../moduleholder/Moduleholder';

// const Header = lazy(() => import(/* webpackIgnore: true */ Globals.modules.header ? Globals.modules.header.jsUrl : '').then(() => ({ default: window.header })));

class App extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        spinner: PropTypes.bool,
        detail: PropTypes.bool,
    };

    static defaultProps = {
        spinner: false,
        detail: undefined,
    };

    constructor(props) {
        super(props);
        $('div.prespinner').remove();
        const helpOpen = localStorage.getItem('helpOpen') === 'true';
        this.state = {
            helpOpen,
            helpText: '',
            userLastname: Globals.user ? Globals.user.get('lastname') : '',
            userFirstname: Globals.user ? Globals.user.get('firstname') : '',
        };
        this.actualModul = Globals.actions.getFirstLink();
        this.clickHelp = this.clickHelp.bind(this);
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            if (action === 'POP') {
                $(document).validationEngine('hideAll');
            }
        });
        this.renderModuleSelect();
    }

    componentWillReceiveProps(nextProps) {
        const state = {};
        if (nextProps.detail !== undefined) {
            state.detail = true;
        }
        this.setState(state);
    }

    onClickModuleSelect() {
        const modulSelected = $('input#modulwahlfeld').attr('value');
        if (this.actualModul !== modulSelected) {
            this.setSpinner(true);
        }
        $(window).unbind('scroll');
        $(window).scrollTo(0, 0);
        $(document).validationEngine('hideAll');
        console.log(this.props.history, modulSelected);
        // this.props.history.push('/index.users');
        this.props.history.push('/' + modulSelected);
        this.actualModul = modulSelected;
    }

    setSpinner(value) {}

    clickHelp(text) {
        const newState = {
            helpOpen: !this.state.helpOpen,
        };
        this.setState(newState, () => {
            if (text) {
                console.log(text);
                this.setState({ helpText: text });
            }
        });
        return false;
    }

    showInterfaces() {
        if (Globals.actions.can('index.interfaces')) {
            $(window).unbind('scroll');
            $(window).scrollTo(0, 0);
            $(document).validationEngine('hideAll');
            this.props.history.push('/index.interfaces');
        }
    }

    renderModuleSelect() {
        const optionArray = [];
        let trennerCount = -1;
        _.each(Globals.actions.getMenuEntries(), (action) => {
            optionArray.push({ value: action.get('code'), label: $._(action.get('code')) });
            if (action.get('moduleOrder') && action.get('moduleOrder') < 200) {
                trennerCount += 1;
            }
        });
        let trennerArray = {};
        if (_.size(optionArray) > 1) {
            // var trenner = $._('index.modul.trenner');
            trennerArray = [{ positionAfter: trennerCount, label: '' }];
        }
        $('#kopf .uiSelect').remove();
        // Prepare Location Name
        const location = this.props.location.pathname.split('/');
        const locationName = location[1];
        $('#kopf').uiselectfield('createSelect', {
            optionArray,
            fieldId: 'modulwahlfeld',
            setValue: locationName,
            setValueString: $._(locationName),
            position: 'append',
            data_help: $._('index.modul.data-help'),
            trennerArray,
            isMenu: true,
        });
        $('#kopf .uiSelect li').click(this.onClickModuleSelect.bind(this));
    }
    render() {
        const { helpOpen, helpText } = this.state;
        const userFirstname = this.state.userFirstname || '';
        const userLastname = this.state.userLastname || '';
        const newModules = ['module.header', 'module.etl', 'module.crossanalytics', 'module.editor', 'info', 'module.log']; // Module die nicht von CSS aus Hauptrahmen beeinflusst werden dürfen
        let isNewModule = false;
        if (newModules.indexOf(this.props.location.pathname.split('/')[1]) !== -1) {
            isNewModule = true;
        }

        const newHelpModules = ['module.etl']; // Module die nicht von CSS aus Hauptrahmen beeinflusst werden dürfen
        let isNewHelpModules = false;
        if (newHelpModules.indexOf(this.props.location.pathname.split('/')[1]) !== -1) {
            isNewHelpModules = true;
        }
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        return (
            <div>
                <div id="content-main" hidden={this.state.detail}>
                    <Header {...this.props} helpAction={this.clickHelp} working={['etl']} />
                    <div id="hauptrahmen">
                        <div id="content">
                            {!isNewModule && (
                                <div>
                                    <AppRoutes {...this.props} spinner={this.setSpinner.bind(this)} enableHelp={this.clickHelp} />
                                    <ModuleRoutes {...this.props} spinner={this.setSpinner.bind(this)} enableHelp={this.clickHelp} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {isNewModule && (
                    <Moduleholder type="default">
                        <ModuleRoutes {...this.props} spinner={this.setSpinner.bind(this)} enableHelp={this.clickHelp} />
                    </Moduleholder>
                )}
                {!isNewHelpModules && <Help open={helpOpen} text={helpText} />}
            </div>
        );
    }
}

export default App;

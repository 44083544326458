import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/slider.css';
import async from 'async';
import _ from 'underscore';
import 'jquery-form';
import 'datejs';
import 'jquery-ui-timepicker-addon';
import { i18n } from '@mabinso-registry/module-commons';
import Globals from './Globals';
import '../vendor/jquery/jquery.jqtextile.js';
import '../vendor/backbone/backbone-sync';
import './UnderscoreHTML';
import '../vendor/backbone/Backbone.CollectionBinder';
import '../vendor/jquery/jquery.tagsinput';
import '../vendor/jquery/jquery.simplemodal';
import '../vendor/jquery/jquery.validationEngine';
import '../vendor/jquery/jquery.validationEngine-custom';
import '../vendor/jquery/jquery.scrollTo';
import '../vendor/jquery/jquery.ui.spinner';
import '../vendor/jquery/jquery.ui.select';
import '../vendor/jquery/jquery.ui.abblender';
import '../vendor/jquery/jquery.ui.pnganimation';
import '../vendor/jquery/jquery.ui.aktivanzeiger';
import '../vendor/jquery/jquery.ui.checkbox';
import AlertView from '../components/app/views/AlertView';
import auth from './Auth';
import ActionUserCollection from '../components/action/models/ActionUserCollection';
import SchemaUserModel from '../components/schema/models/SchemaUserModel';
import UserModel from '../components/user/models/UserModel';
import Ajax from './Ajax';
import InterfacerunView from '../components/interface/views/InterfacerunView';
import '../css/screen.scss';

class Init {
    static init(options) {
        const that = this;
        this.initJqueryAjax();
        this.initServerConfig({
            success: () => {
                this.initI18n({
                    success: () => {
                        that.initDatepicker();
                        that.initDate();
                        options.success();
                    },
                });
            },
        });
    }

    static initAfterLogin(options) {
        const that = this;
        if (auth.loggedIn()) {
            this.initInterfaceRunViewPolling();
            this.initJqueryAjaxToken();
            async.parallel(
                [
                    (callback) => {
                        that.initActionUserCollection({
                            success: () => {
                                callback();
                            },
                        });
                    },
                    (callback) => {
                        that.initSchemaUserModel({
                            success: () => {
                                callback();
                            },
                        });
                    },
                    (callback) => {
                        that.initSessionUser({
                            success: () => {
                                that.initI18n({
                                    success: () => {
                                        callback();
                                    },
                                });
                            },
                        });
                    },
                ],
                () => {
                    options.success();
                }
            );
        }
    }

    static initInterfaceRunViewPolling() {
        Globals.interfaceRunView = new InterfacerunView();
    }

    static initI18n(options) {
        let lang = auth.getLang();
        if (Globals.user !== null) {
            const userlang = Globals.user.get('lang');
            if (userlang !== lang) {
                this.loadLang(userlang, options);
            } else {
                options.success();
            }
        } else {
            if (_.isUndefined(lang) || lang === '') {
                lang = 'de';
                if (!_.contains(Globals.serverConfig.application_langs.split(','), lang)) {
                    lang = 'en';
                }
            }
            this.loadLang(lang, options);
        }
    }

    static loadLang(lang, options) {
        auth.setLang(lang);
        const langAjax = new Ajax({ baseUrl: Globals.rootUrl + '/static/' });
        langAjax.getJson('locales/' + lang + '/translation.json').then((data) => {
            const resources = {};
            resources[lang] = {
                translation: data,
            };
            i18n.addResourceBundle(lang, 'translation', data);
            i18n.changeLanguage(lang);
            const oldFunction = (text, ...args) => {
                const oargs = {};
                for (let i = 0; i < args.length; i += 1) {
                    oargs[i + ''] = args[i];
                }
                return i18n.t(text, oargs);
            };
            i18n._ = oldFunction;
            i18n.data = i18n.getResourceBundle(lang, 'translation');
            i18n.lang = lang;
            Globals.i18n = i18n;
            if (window.config) {
                window.config.i18n = i18n;
            }
            $._ = oldFunction;
            options.success();
        });
    }

    static initServerConfig(options) {
        Ajax.getJson('config').then((data) => {
            Globals.serverConfig = data;
            options.success();
        });
    }

    static initSessionUser(options) {
        console.log('---------------------->');
        const userModel = new UserModel();
        userModel.fetch({
            success(item) {
                console.log('---------------------->');
                Globals.user = item;
                Globals.version = item.get('version');
                Globals.baseLangs = item.get('baseLangs');
                options.success();
            },
        });
    }

    static initSchemaUserModel(options) {
        Globals.schemaUserModel = new SchemaUserModel();
        if (auth.loggedIn()) {
            Globals.schemaUserModel.fetch({
                success() {
                    if (_.isFunction(options.success)) {
                        options.success();
                    }
                },
            });
        } else {
            options.success();
        }
    }

    static initActionUserCollection(options) {
        Globals.actions = new ActionUserCollection();
        if (auth.loggedIn()) {
            Globals.actions.fetch({
                success() {
                    if (_.isFunction(options.success)) {
                        options.success();
                    }
                },
            });
        } else {
            options.success();
        }
    }

    static initJqueryAjaxToken() {
        if (auth.loggedIn()) {
            const header = {};
            // console.log('the Token-------------------------------------------', auth.getToken(), localStorage.token);
            header[auth.getHeaderName()] = auth.getToken();
            $.ajaxSetup({
                cache: true,
                headers: header,
            });
        }
    }

    static initJqueryAjax() {
        $(document).ajaxError((e, jqxhr) => {
            const { status } = jqxhr;
            let { responseText } = jqxhr;
            console.log(jqxhr, jqxhr.statusCode());
            if (jqxhr.statusText !== 'abort') {
                let callback = () => {};
                if (status === 200) {
                    responseText = 'alert.error.session';
                    callback = () => {
                        window.location.reload();
                    };
                }
                if (status === 403 || status === 404) {
                    let message;
                    if (!responseText || responseText === '') {
                        responseText = 'alert.error.forbidden';
                    } else {
                        try {
                            message = JSON.parse(responseText);
                            responseText = message.message;
                        } catch (exp) {
                            console.error('Json Parse Error');
                        }
                    }
                }
                if (status === 402) {
                    responseText = 'alert.error.licenseExpired';
                    callback = () => {
                        window.location = '/license-expired';
                    };
                }
                if (status === 403) {
                    callback = () => {
                        window.location = '/logout';
                    };
                }
                if (status === 0) {
                    responseText = 'alert.error.server.offline';
                    callback = () => {
                        window.location = '/logout';
                    };
                }
                $('body').abblender('close');
                if (Globals.i18n.data[responseText]) {
                    responseText = Globals.i18n._(responseText);
                }
                if (status !== 201) {
                    setTimeout(() => {
                        AlertView.show({
                            title: $._('alert.error.general') + ' (' + status + ')',
                            message: responseText,
                            okFunc: callback,
                        });
                    }, 2000);
                }
            }
        });
    }

    static initDatepicker() {
        $.datepicker.regional[Globals.i18n.lang] = {
            closeText: $._('date.calendar.closeText'),
            prevText: $._('date.calendar.prevText'),
            nextText: $._('date.calendar.nextText'),
            currentText: $._('date.calendar.currentText'),
            monthNames: [
                $._('date.month.january'),
                $._('date.month.febuary'),
                $._('date.month.march'),
                $._('date.month.april'),
                $._('date.month.may'),
                $._('date.month.june'),
                $._('date.month.july'),
                $._('date.month.august'),
                $._('date.month.september'),
                $._('date.month.october'),
                $._('date.month.november'),
                $._('date.month.december'),
            ],
            monthNamesShort: [
                $._('date.month.january.short'),
                $._('date.month.febuary.short'),
                $._('date.month.march.short'),
                $._('date.month.april.short'),
                $._('date.month.may.short'),
                $._('date.month.june.short'),
                $._('date.month.july.short'),
                $._('date.month.august.short'),
                $._('date.month.september.short'),
                $._('date.month.october.short'),
                $._('date.month.november.short'),
                $._('date.month.december.short'),
            ],
            dayNames: [$._('date.day.sunday'), $._('date.day.monday'), $._('date.day.tuesday'), $._('date.day.wednesday'), $._('date.day.thursday'), $._('date.day.friday'), $._('date.day.saturday')],
            dayNamesShort: [
                $._('date.day.sunday.short'),
                $._('date.day.monday.short'),
                $._('date.day.tuesday.short'),
                $._('date.day.wednesday.short'),
                $._('date.day.thursday.short'),
                $._('date.day.friday.short'),
                $._('date.day.saturday.short'),
            ],
            dayNamesMin: [
                $._('date.day.sunday.short'),
                $._('date.day.monday.short'),
                $._('date.day.tuesday.short'),
                $._('date.day.wednesday.short'),
                $._('date.day.thursday.short'),
                $._('date.day.friday.short'),
                $._('date.day.saturday.short'),
            ],
            weekHeader: $._('date.calendar.weekHeader'),
            dateFormat: $._('date.calendar.dateFormat'),
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: '',
        };
        $.datepicker.setDefaults($.datepicker.regional[Globals.i18n.lang]);
    }

    static initDate() {
        Date.CultureInfo = {
            name: Globals.i18n.lang,
            englishName: $._('date.englishName'),
            nativeName: $._('date.nativeName'),
            dayNames: [$._('date.day.sunday'), $._('date.day.monday'), $._('date.day.tuesday'), $._('date.day.wednesday'), $._('date.day.thursday'), $._('date.day.friday'), $._('date.day.saturday')],
            abbreviatedDayNames: [
                $._('date.day.sunday.short'),
                $._('date.day.monday.short'),
                $._('date.day.tuesday.short'),
                $._('date.day.wednesday.short'),
                $._('date.day.thursday.short'),
                $._('date.day.friday.short'),
                $._('date.day.saturday.short'),
            ],
            shortestDayNames: [
                $._('date.day.sunday.short'),
                $._('date.day.monday.short'),
                $._('date.day.tuesday.short'),
                $._('date.day.wednesday.short'),
                $._('date.day.thursday.short'),
                $._('date.day.friday.short'),
                $._('date.day.saturday.short'),
            ],
            firstLetterDayNames: [
                $._('date.day.sunday.short'),
                $._('date.day.monday.short'),
                $._('date.day.tuesday.short'),
                $._('date.day.wednesday.short'),
                $._('date.day.thursday.short'),
                $._('date.day.friday.short'),
                $._('date.day.saturday.short'),
            ],
            monthNames: [
                $._('date.month.january'),
                $._('date.month.febuary'),
                $._('date.month.march'),
                $._('date.month.april'),
                $._('date.month.may'),
                $._('date.month.june'),
                $._('date.month.july'),
                $._('date.month.august'),
                $._('date.month.september'),
                $._('date.month.october'),
                $._('date.month.november'),
                $._('date.month.december'),
            ],
            abbreviatedMonthNames: [
                $._('date.month.january.short'),
                $._('date.month.febuary.short'),
                $._('date.month.march.short'),
                $._('date.month.april.short'),
                $._('date.month.may.short'),
                $._('date.month.june.short'),
                $._('date.month.july.short'),
                $._('date.month.august.short'),
                $._('date.month.september.short'),
                $._('date.month.october.short'),
                $._('date.month.november.short'),
                $._('date.month.december.short'),
            ],
            amDesignator: '',
            pmDesignator: '',
            firstDayOfWeek: 1,
            twoDigitYearMax: 2029,
            dateElementOrder: 'dmy',
            formatPatterns: {
                shortDate: $._('date.formatPatterns.shortDate'),
                longDate: $._('date.formatPatterns.longDate'),
                shortTime: $._('date.formatPatterns.shortTime'),
                longTime: $._('date.formatPatterns.longTime'),
                fullDateTime: $._('date.formatPatterns.fullDateTime'),
                sortableDateTime: 'yyyy-MM-ddTHH:mm:ss',
                universalSortableDateTime: 'yyyy-MM-dd HH:mm:ssZ',
                rfc1123: 'ddd, dd MMM yyyy HH:mm:ss GMT',
                monthDay: $._('date.formatPatterns.monthDay'),
                yearMonth: $._('date.formatPatterns.yearMonth'),
                parseExact: $._('date.formatPatterns.parseExact').split(';'),
            },
            // TODO: I18N
            regexPatterns: {
                jan: /^jan(uar)?/i,
                feb: /^feb(ruar)?/i,
                mar: /^märz/i,
                apr: /^apr(il)?/i,
                may: /^mai/i,
                jun: /^jun(i)?/i,
                jul: /^jul(i)?/i,
                aug: /^aug(ust)?/i,
                sep: /^sep(t(ember)?)?/i,
                oct: /^okt(ober)?/i,
                nov: /^nov(ember)?/i,
                dec: /^dez(ember)?/i,

                sun: /^sonntag/i,
                mon: /^montag/i,
                tue: /^dienstag/i,
                wed: /^mittwoch/i,
                thu: /^donnerstag/i,
                fri: /^freitag/i,
                sat: /^samstag/i,

                future: /^next/i,
                past: /^last|past|prev(ious)?/i,
                add: /^(\+|after|from)/i,
                subtract: /^(-|before|ago)/i,

                yesterday: /^yesterday/i,
                today: /^t(oday)?/i,
                tomorrow: /^tomorrow/i,
                now: /^n(ow)?/i,

                millisecond: /^ms|milli(second)?s?/i,
                second: /^sec(ond)?s?/i,
                minute: /^min(ute)?s?/i,
                hour: /^h(ou)?rs?/i,
                week: /^w(ee)?k/i,
                month: /^m(o(nth)?s?)?/i,
                day: /^d(ays?)?/i,
                year: /^y((ea)?rs?)?/i,

                shortMeridian: /^(a|p)/i,
                longMeridian: /^(a\.?m?\.?|p\.?m?\.?)/i,
                timezone: /^((e(s|d)t|c(s|d)t|m(s|d)t|p(s|d)t)|((gmt)?\s*(\+|-)\s*\d\d\d\d?)|gmt)/i,
                ordinalSuffix: /^\s*(st|nd|rd|th)/i,
                timeContext: /^\s*(:|a|p)/i,
            },
            abbreviatedTimeZoneStandard: {
                GMT: '-000',
                EST: '-0400',
                CST: '-0500',
                MST: '-0600',
                PST: '-0700',
            },
            abbreviatedTimeZoneDST: {
                GMT: '-000',
                EDT: '-0500',
                CDT: '-0600',
                MDT: '-0700',
                PDT: '-0800',
            },
        };
    }
}

export default Init;

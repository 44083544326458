import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Download from '../../../utils/Download';
import Globals from '../../../utils/Globals';
import WaitView from '../../app/views/WaitView';
import AlertView from '../../app/views/AlertView';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../templates/report_saved_view.template';

const ReportSavedView = Backbone.View.extend({
    initialize(options) {
        this.options = options;
    },
    render() {
        const view = this;
        const tstring = _.template(html);
        view.$el.html(
            tstring({
                model: view.model,
                $,
                Actions: Globals.actions,
                config: Globals.serverConfig,
            })
        );
        view.setStatus();
    },
    hideForSearch(value) {
        if (value) {
            this.$el.addClass('search-hide');
        } else {
            this.$el.removeClass('search-hide');
        }
    },
    getStatus() {
        return this.model.getStatus().code;
    },
    setStatus(model) {
        const view = this;
        if (!_.isUndefined(model)) {
            this.model = model;
        }
        const status = _.make(
            'span',
            {
                class: 'status_' + view.model.getStatus().code + ' sprites_main hint',
                title: view.model.getStatus().msg,
            },
            view.model.getStatus().msg
        );
        this.$('td.status').html(status);
        if (view.model.hasStatus('fehler')) {
            view.$('.show-buttons').hide();
            view.$('.bearbeiten').hide();
            view.$('.loeschen').show();
        } else if (view.model.hasStatus('ok_empty')) {
            view.$('.show-buttons').hide();
            view.$('.bearbeiten').show();
            view.$('.loeschen').show();
        } else if (view.model.hasStatus('inaktion')) {
            view.$('.show-buttons').hide();
            view.$('.bearbeiten').hide();
            // view.$('.loeschen').hide();
        } else {
            view.$('.show-buttons').show();
            view.$('.bearbeiten').show();
            view.$('.loeschen').show();
        }
    },
    isNew() {
        if (this.$el.hasClass('new')) {
            return true;
        }
        return false;
    },
    setNew(value) {
        if (value) {
            this.$el.addClass('new');
            this.$el.removeClass('old');
        } else {
            this.$el.addClass('old');
            this.$el.removeClass('new');
        }
    },
    isHidden() {
        if (this.$el.hasClass('search-hide')) {
            return true;
        }
        return false;
    },
    triggerPdf() {
        this.triggerReport('pdf');
    },
    triggerReport(report) {
        this.$('a.link-' + report).trigger('click');
    },
    events: {
        'click .loeschen:not(.abbrechen)': 'deleteSavedReport',
        'click .bearbeiten:not(.active)': 'editSavedReport',
        'click .loeschen.abbrechen': 'cancelEditSavedReport',
        'keydown input': 'keyDownEditSavedReport',
        'click .bearbeiten.active': 'saveSavedReport',
        'click a.link-pdf': 'getPdfSavedReport',
        'click a.link-xls': 'getXlsSavedReport',
        'click a.link-csv': 'getCsvSavedReport',
        'click a.link-html': 'getHtmlSavedReport',
        'click .listSelector': 'toggleSelectReport',
    },
    deleteSavedReport() {
        const view = this;
        console.log('deleteSavedReport');
        if (view.$el.hasClass('selected')) {
            const wait = new WaitView({
                message: $._('report.alert.report.delete.wait'),
            });
            view.model.destroy({
                success() {
                    view.options.reportView.removeReportSavedView(view);
                    wait.remove();
                },
            });
        } else {
            ConfirmView.show({
                message: $._('report.alert.report.delete.wait'),
                title: $._('alert.action.delete'),
                yesString: $._('report.alert.report.delete.confirm'),
                yesFunc() {
                    // cview.remove();
                    const wait = new WaitView({
                        message: $._('report.alert.report.delete.wait'),
                    });
                    view.model.destroy({
                        success() {
                            view.options.reportView.removeReportSavedView(view);
                            wait.remove();
                        },
                    });
                },
            });
        }
        view.closeSelectModeComplete();
    },
    showEditModus() {
        const view = this;

        // Abblenden für edit modus
        $(document).abblender({ onClick() {}, scrolling: false });
        view.$el.css('z-index', '1002').css('position', 'absolute'); // addClass('uiOverAbblender');
        // REALLY REALLY BAD but this is a table...
        view.$el.after('<tr><td>&nbsp;</td></tr>');
        view.options.reportView.$('table').addClass('edit');
    },
    hideEditModus() {
        const view = this;
        $(document).abblender('close');
        view.$el.css('z-index', '').css('position', '');
        view.options.reportView.$('table').removeClass('edit');
        view.$el.next().remove();
    },
    editSavedReport(evt) {
        const view = this;
        const target = $(evt.target);
        $(evt.target).closest('td').find('.listSelector').hide();
        view.showEditModus();

        let i = 0;
        const editables = view.$('.editable');
        editables.each(function each() {
            if ($(this).hasClass('simple')) {
                const thisWidth = $(this).width() - 15;
                const getName = $(this).data('input-name');
                const newDom = _.make('input', {
                    type: 'text',
                    name: getName,
                    class: 'textinput last',
                    value: view.model.get(getName) != null ? view.model.get(getName).trim().replace(/ +/, ' ') : $._('report.list.template.description'),
                    style: 'z-index:2000',
                });
                if ($(this).data('maxlength')) {
                    $.attr(newDom, 'maxlength', $(this).data('maxlength'));
                }
                // var newDom='<input type="text" name="'+getName+'" class="textinput last" value="'++'"/>';
                $(this).html(newDom);
                const newInput = $(this).find('input');
                newInput.css('width', thisWidth + 'px');
                $(this).addClass('active');
                if (i === 0) {
                    newInput.focus();
                    newInput.select();
                }
            }
            i += 1;
        });
        target.attr('title', $._('dialog.save'));
        target.addClass('active');
        view.$('.loeschen').attr('title', $._('dialog.cancel'));
        view.$('.loeschen').addClass('abbrechen');
        view.$el.removeClass('hover');
        view.$el.addClass('showHover');
    },
    keyDownEditSavedReport(evt) {
        const view = this;
        const target = $(evt.target);
        evt.stopPropagation();
        if (target.hasClass('last')) {
            if (evt.keyCode === 9) {
                evt.preventDefault();
                target.focus();
            }
        }
        if (evt.keyCode === 13) {
            evt.preventDefault();
            view.$('.bearbeiten.active').trigger('click');
        }
        if (evt.keyCode === 27) {
            view.$('.abbrechen').trigger('click');
        }
    },
    cancelEditSavedReport(evt) {
        const view = this;
        const target = $(evt.target);
        view.$('.editable').each(function each() {
            const getName = $(this).data('input-name');
            $(this).html(view.model.get(getName) != null ? view.model.get(getName).trim().replace(/ +/, ' ') : $._('report.list.template.description'));
        });
        view.$('.bearbeiten').attr('title', $._('dialog.edit'));
        view.$('.bearbeiten').removeClass('active');
        target.attr('title', $._('dialog.delete'));
        target.addClass('loeschen');
        target.removeClass('abbrechen');
        view.$el.addClass('hover');
        view.$el.removeClass('showHover');
        $(evt.target).closest('td').find('.listSelector').show();
        view.hideEditModus();
    },
    saveSavedReport() {
        const view = this;
        // var target = $(evt.target);
        const oldDescription = view.model.get('description');
        view.model.setForm(view.options.reportView.$('form'));
        const wait = new WaitView({
            message: $._('report.alert.report.save'),
            title: $._('alert.wait.title'),
        });
        view.model.save({
            success(formMessage) {
                if (formMessage.hasErrors()) {
                    view.model.set('description', oldDescription);
                    formMessage.showErrors();
                } else {
                    view.$('.abbrechen').trigger('click');
                }
                wait.remove();
                $.modal.close();
            },
        });
    },
    getPdfSavedReport() {
        this.embedSavedReport('pdf');
        return false;
    },
    getXlsSavedReport() {
        this.embedSavedReport('xls');
        return false;
    },
    getCsvSavedReport() {
        this.embedSavedReport('csv');
        return false;
    },
    getHtmlSavedReport() {
        // $('#reportzeilen').hide();
        if (this.model.get('isBatch')) {
            this.embedSavedReport('html');
        } else {
            document.getElementById('reportzeilen').style.display = 'none';
            const view = this;
            const browserHistory = this.options.reportView.options.reportCategoryView.options.reportListView.options.history;
            browserHistory.push('/index.reports/' + view.model.id);
        }
        return false;
    },
    embedSavedReport(type) {
        const view = this;
        const wait = new WaitView({
            message: $._('report.alert.get.report'),
            title: $._('alert.wait.title'),
        });

        Download.do({
            url: view.model.urlContent(type),
            success() {
                view.setNew(false);
                wait.remove();
            },
            error(json) {
                wait.remove();
                AlertView.show({
                    title: $._('alert.error.general'),
                    message: $._('alert.error.what') + '<br>' + (Globals.i18n.data[json.message] ? $._(json.message) : json.message),
                    okFunc() {},
                });
            },
        });
    },
    remove() {
        const view = this;
        let toDetach = null;
        if (this.$el.closest('.reports').find('.report').length === 1) {
            toDetach = this.$el.closest('.reports');
            this.$el.closest('.reportvorlage').find('.reporttitel').removeClass('active');
        } else {
            toDetach = this.$el;
        }
        toDetach.fadeOut('fast', () => {
            view.$el.detach();
        });
    },
    toggleSelectReport(e) {
        if (!$(e.target).closest('.report').is(':visible')) {
            return false;
        }
        const vorlage = $(e.target).closest('.reportvorlage');
        const category = $(e.target).closest('.reportcategory');

        // Geklickter Report
        if ($(e.target).hasClass('false')) {
            $(e.target).removeClass('false');
            $(e.target).addClass('true');
            $(e.target).closest('.report').addClass('selected');
        } else {
            $(e.target).removeClass('true');
            $(e.target).addClass('false');
            $(e.target).closest('.report').removeClass('selected');
        }

        // Testen ob Vorlage komplett aktiviert
        vorlage.find('.reporttitel, .buttonsammlung_reportzeilen .listSelector').removeClass('true').removeClass('some').removeClass('false');
        let lengthSelected = vorlage.find('.listSelector.true').length;
        let lengthReports = vorlage.find('.reports .listSelector').length;
        let newstate = 'some';
        switch (lengthSelected) {
            case 0:
                newstate = 'false';
                break;
            case lengthReports:
                newstate = 'true';
                break;
            default:
                newstate = 'some';
        }
        vorlage.find('.buttonsammlung_reportzeilen .listSelector, .reporttitel, .typbeschriftung').addClass(newstate);

        // Testen ob Kategorie komplett aktiviert
        category.find('.typbeschriftung, .typbeschriftung .listSelector').removeClass('true').removeClass('some').removeClass('false');
        lengthSelected = category.find('.reports .listSelector.true').length;
        lengthReports = category.find('.reports .listSelector').length;
        switch (lengthSelected) {
            case 0:
                newstate = 'false';
                break;
            case lengthReports:
                newstate = 'true';
                break;
            default:
                newstate = 'some';
        }
        category.find('.typbeschriftung, .typbeschriftung .listSelector').addClass(newstate);

        // Testet ob der Auswahlmoduks überhaupt aktiv ist
        lengthSelected = $('#reportzeilen').find('.listSelector.true').length;
        if (lengthSelected > 0) {
            $('#reportzeilen:not(.selectmode)').addClass('selectmode');
        } else {
            $('#reportzeilen.selectmode').removeClass('selectmode');
        }
    },
    closeSelectModeComplete() {
        $('#reportzeilen.selectmode').removeClass('selectmode');
        $('.reportvorlage .reporttitel, .buttonsammlung_reportzeilen .listSelector').removeClass('true').removeClass('some').removeClass('false');
        $('.reportcategory .typbeschriftung, .typbeschriftung .listSelector').removeClass('true').removeClass('some').removeClass('false');
        $('.listSelector:not(.false)').addClass('false');
    },
});

export default ReportSavedView;

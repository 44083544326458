import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import InterfaceView from './InterfaceView';
import InterfaceCollection from '../models/InterfaceCollection';
import ConfirmView from '../../app/views/ConfirmView';
import html from '../templates/category_view.template';
import Globals from '../../../utils/Globals';

const View = Backbone.View.extend({
    tagName: 'div',
    className: 'reportcategory reporttyp',
    _modelBinder: undefined,
    initialize(options) {
        this.options = options;
        this._modelBinder = new Backbone.ModelBinder();
    },
    render() {
        const view = this;
        const tstring = _.template(html);
        this.$el.html(tstring({ $, Actions: Globals.actions }));
        view.$el.find('.waitreport').pnganimation('init', { time: 50 });
        this._modelBinder.bind(view.model, this.el, Backbone.ModelBinder.createDefaultBindings(this.el, 'data-name'));
        view.collection = new InterfaceCollection();
        view.collection.fetch({
            data: { category_name: view.model.get('name') },
            success(collection) {
                collection.sortByField('id', 'asc');
                view.$('.typbeschriftung .waitreport').pnganimation('stop', { left: -915, top: -205 });
                if (collection.isEmpty()) {
                    view.hasInterface(false);
                } else {
                    view.hasInterface(true);
                    const viewCreator = model => { model.view = new InterfaceView({ model, parent: view }); return model.view; };
                    const elManagerFactory = new Backbone.CollectionBinder.ViewManagerFactory(viewCreator);
                    view._collectionBinder = new Backbone.CollectionBinder(elManagerFactory);
                    view._collectionBinder.bind(collection, view.$('.reportvorlagensammlung'));
                    view.setInterfaceCount(collection.size());
                }
            }
        });
        return this;
    },
    hasInterface(value) {
        if (value) {
            this.$el.show();
            this.$('.typbeschriftung .waitreport').removeClass('waitreport');
            this.$('.typbeschriftung').addClass('hasReports');
            this.$('.typbeschriftung div div').removeClass('noreport');
            // Es gibt mindestens eine Sammlung, die weitere Unterpunkte enthält. Die Mathode zum öffnen, wenn es nur einen gibt wird der aufgerufen
            this.options.interfaceListView.onlyone();
        } else {
            console.log('hasNoInterface');
            this.$('.typbeschriftung').removeClass('hasReports');
            this.$('.typbeschriftung div div').addClass('noreport').addClass('sprites_main');
        }
    },
    setInterfaceCount(value) {
        this.$('.interface-count').html(value);
    },
    triggerCategory(open) {
        const opener = this.$('.typbeschriftung .opentype');
        if (_.isUndefined(open)) {
            opener.trigger('click');
        } else {
            if (open && !opener.hasClass('active')) {
                opener.trigger('click');
            }
            if (!open && opener.hasClass('active')) {
                opener.trigger('click');
            }
        }
    },
    events: {
        'click .typbeschriftung.hasReports .opentype.active': 'close',
        'click .typbeschriftung.hasReports .opentype:not(.active)': 'open',
        'click .deleteCategory': 'deleteCategory',
        'click .editCategory': 'editCategory',
        'click .addReport': 'addCategory',
        'click .buttonsammlung_reportzeilen .listSelector': 'toggleSelectReportAllSaved',
        'click .typbeschriftung .listSelector': 'toggleSelectReportAllCategory'
    },
    toggleSelectReportAllSaved(e) {
        const view = this;
        if ($(e.target).hasClass('true')) {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportvorlage'), 'true');
        } else {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportvorlage'), 'false');
        }
        return false;
    },
    toggleSelectReportAllCategory(e) {
        const view = this;
        if ($(e.target).hasClass('true')) {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportcategory'), 'true');
        } else {
            view.updateSelectStatusReportcategory($(e.target).closest('.reportcategory'), 'false');
        }
        return false;
    },
    updateSelectStatusReportcategory(vorlage, status) {
        if (status === 'true') {
            vorlage.find('.reports .listSelector.true').trigger('click');
        } else {
            vorlage.find('.reports .listSelector.false').trigger('click');
        }
        return false;
    },
    /* addCategory:function(){
        var view = this;
        if(_.isUndefined(view.options.editReportView)){
            view.options.editReportView = new EditReportView({
                reportCategoryView: view,
                reportListView:view.options.reportListView
            });
        }
        view.options.editReportView.model = new ReportModel();
        view.options.editReportView.render();
    },
    editCategory:function(){
        var view = this;
        if(_.isUndefined(view.options.reportListView.options.editReportCategoryView)){
            view.options.reportListView.options.editReportCategoryView = new EditCategoryView({
                reportListView:view.options.reportListView
            });
        }
        view.options.reportListView.options.editReportCategoryView.model = view.model;
        view.options.reportListView.options.editReportCategoryView.render();
    }, */
    deleteCategory() {
        const view = this;
        ConfirmView.show({
            message: $._('interface.alert.reportcategory.confirm.delete'),
            title: $._('alert.action.delete'),
            yesString: $._('alert.action.delete'),
            yesFunc() {
                view.model.destroy({
                    success() {
                        view.options.reportListView.render();
                    }
                });
            }
        });
    },
    open(evt) {
        const root = $(evt.target).closest('.reportcategory');
        $('.typinfo_outer', root).fadeOut('fast', () => {
            $('.reportvorlagensammlung', root).slideDown('fast');
            $('.typbeschriftung', root).addClass('active');
            $(evt.target).addClass('active');
            if (window.scroll) {
                $.scrollTo($('.typbeschriftung', root).offset().top - 213, 200);
            }
        });
    },
    close(evt) {
        const root = $(evt.target).closest('.reportcategory');
        $('.reportvorlagensammlung', root).slideUp('fast', () => {
            $('.typinfo_outer', root).fadeIn('fast');
            $('.typbeschriftung', root).removeClass('active');
            $(evt.target).removeClass('active');
        });
    }
});
export default View;

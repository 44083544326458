import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import UserView from './views/UserView';

class User extends React.Component {
    static propTypes = {
        spinner: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const actview = new UserView();
        $('#user_component').html($(actview.el));
        actview.render();
        this.props.spinner(false);
    }

    componentWillUnmount() {
        // remove all validation error messages
        $(document).validationEngine('hideAll');
    }

    render() {
        return (
            <div id="user_component" />
        );
    }
}

export default User;

import React from 'react';
import PropTypes from 'prop-types';
import './Moduleholder.scss';

class Moduleholder extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        type: PropTypes.string,
    };

    static defaultProps = {
        type: 'default',
    };

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const { type, children } = this.props;
        return (
            <div className={`Moduleholder  ${type}`}>
                {children}
            </div>
        );
    }
}
export default Moduleholder;
